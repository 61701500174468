<template>
  <div>
    <div style="margin-top: 10px">
      <van-field
        v-model="positionFrom.Name"
        label="企业名称"
        required
        placeholder="请输入企业名称"
      />
      <van-field
        v-model="positionFrom.ShortName"
        label="企业简称"
        placeholder="请输入企业简称"
      />
      <van-field
        v-model="positionFrom.ETypeDesc"
        label="企业类型"
        placeholder="请选择企业类型"
        @click="EType = true"
        readonly="readonly"
      />
      <van-popup v-model="EType" position="bottom" get-container="body">
        <van-picker
          show-toolbar
          :columns="leixing"
          value-key="Name"
          @cancel="EType = false"
          @confirm="onEType"
        >
        </van-picker>
      </van-popup>
      <van-field
        v-model="positionFrom.IndName"
        label="所属行业"
        required
        placeholder="请选择所属行业"
        @click="AbilityCode = true"
        readonly="readonly"
      />
      <van-popup v-model="AbilityCode" position="bottom" get-container="body">
        <!-- <van-cascader
          title="请选择所属行业"
          :options="hangye"
          @close="AbilityCode = false"
          :field-names="{ text: 'Name', value: 'Coding', children: 'Children' }"
          @finish="onAbilityCode"
        /> -->
        <van-picker
          show-toolbar
          :columns="hangye"
          value-key="Name"
          @cancel="AbilityCode = false"
          @confirm="onAbilityCode"
        >
        </van-picker>
      </van-popup>
      <van-field
        v-model="positionFrom.LicCode"
        label="营业执照"
        required
        placeholder="请输入营业执照"
      />
      <van-field
        v-model="positionFrom.CoScale"
        label="企业规模"
        placeholder="请输入企业人数"
      />
      <van-field
        v-model="positionFrom.Charger"
        label="负责人"
        required
        placeholder="请输入负责人"
      />
      <van-field
        v-model="positionFrom.TouchTel"
        label="负责人电话"
        required
        placeholder="请输入负责人电话"
      />
      <van-field
        v-model="positionFrom.FoundDate"
        label="成立日期"
        @click="FoundDate = true"
        readonly="readonly"
        placeholder="请输入成立日期"
      />
      <van-popup v-model="FoundDate" position="bottom" get-container="body">
        <van-datetime-picker
          show-toolbar
          type="date"
          title="选择成立日期"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="FoundDate = false"
          @confirm="onFoundDate"
        >
        </van-datetime-picker>
      </van-popup>
      <van-field
        v-model="positionFrom.RegCapital"
        label="注册资本"
        placeholder="请输入注册资本"
      />
      <van-field
        v-model="positionFrom.CoTel"
        label="固定电话"
        placeholder="请输入固定电话"
      />
      <van-field
        v-model="positionFrom.CoEMail"
        label="企业邮箱"
        placeholder="请输入企业邮箱"
      />
      <van-field
        v-model="positionFrom.Homepage"
        label="企业主页"
        placeholder="请输入企业主页"
      />
      <van-field
        v-model="positionFrom.MicroBlog"
        label="企业微博"
        placeholder="请输入企业微博"
      />
      <van-field
        v-model="positionFrom.WeChat"
        label="企业公众号"
        placeholder="请输入企业公众号"
      />
      <van-field
        v-model="positionFrom.Address"
        label="地址"
        required
        placeholder="请输入地址"
      />
      <van-field
        v-model="positionFrom.BusinessScope"
        label="经营范围"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入经营范围"
      />
      <van-field
        v-model="positionFrom.Summary"
        label="简介"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入简介"
      />
      <van-field
        v-model="positionFrom.Remark"
        label="备注"
        rows="2"
        autosize
        type="textarea"
        placeholder="请输入备注"
      />
      <!-- <van-field
            v-model="positionFrom.WorkAddrName"
            label="工作地点"
            placeholder="请选择工作地点"
            @click="WorkAddrCode = true"
            readonly="readonly"
          />
          <van-popup
            v-model="WorkAddrCode"
            position="bottom"
            get-container="body"
          >
            <van-cascader
              title="请选择工作地点"
              :options="NativeCantonCodedata"
              @close="WorkAddrCode = false"
              :field-names="{
                text: 'text',
                value: 'value',
                children: 'children',
              }"
              @finish="onWorkAddrCode"
            />
          </van-popup> -->
      <div class="fromButton">
        <van-button @click="SaveJob" round type="info">提 交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
import {
  RE_SaveJob,
  GetAbilityList,
  RE_SaveEnterprise,
  RE_GetMyEnterprise,
} from "@/api/REplat";
import { GetCodeEntrysList } from "@/api/ResumeJS";
import { getaccToken, setaccToken } from "@/utils/auth";
export default {
  data() {
    return {
      positionFrom: {
        //保存
        EId: 0, //企业Id、
        Name: "", //名称、
        ShortName: "", //简称、
        EType: "", //类型（类别：254）、
        RegCapital: "", //注册资本、
        IndCode: "", //行业编码、
        Charger: "", //负责人、
        TouchTel: "", //负责人联系方式、
        Address: "", //地址、
        Coordinate: "", //坐标、
        CoScale: "", //企业规模、
        CoTel: "", //电话、
        CoEMail: "", //邮箱、
        Homepage: "", //主页、
        MicroBlog: "", //微博、
        WeChat: "", //微信公众号、
        FoundDate: "", //成立日期、
        Summary: "", //简介、
        License: "", //营业执照、
        LicCode: "", //营业执照编号、
        BusinessScope: "", //经营范围、
        Remark: "", //备注
        accToken: getaccToken(),
      },
      // 选择器
      zhiye: [],
      xveli: [],
      hangye: [],
      NativeCantonCodedata: [],
      jinji: [
        { id: 1, text: "一般" },
        { id: 2, text: "紧急" },
        { id: 3, text: "非常紧急" },
      ],
      zhuangtai: [
        { id: 1, text: "招聘中" },
        { id: 1, text: "暂缓招聘" },
        { id: 1, text: "招聘结束" },
      ],
      minDate: new Date(1970, 0),
      maxDate: new Date(),
      //选择器显示与隐藏
      Education: false,
      Status: false,
      EType: false,
      Urgency: false,
      AbilityCode: false,
      WorkAddrCode: false,
      FoundDate: false,
    };
  },
  created() {
    this.GetMyEnterprise();
    this.zidianlist();
  },
  methods: {
    // 保存
    SaveJob: function () {
      if (this.positionFrom.Name == "") {
        Toast.fail("企业名称不能为空!");
        return false;
      }
      if (this.positionFrom.Charger == "") {
        Toast.fail("负责人不能为空!");
        return false;
      }
      if (this.positionFrom.TouchTel == "") {
        Toast.fail("负责人联系电话不能为空!");
        return false;
      }
      if (this.positionFrom.Address == "") {
        Toast.fail("地址不能为空!");
        return false;
      }
      if (this.positionFrom.LicCode == "") {
        Toast.fail("营业执照编号不能为空!");
        return false;
      }
      if (this.positionFrom.FoundDate == "") {
        Toast.fail("成立时间不能为空!");
        return false;
      }
      //保存职位
      this.positionFrom.accToken = getaccToken();
      RE_SaveEnterprise(this.positionFrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.$router.push({ path: "/list/company" });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
    // 获取企业详情
    GetMyEnterprise: function () {
      RE_GetMyEnterprise({ accToken: getaccToken() }).then((res) => {
        console.log(res);
        this.positionFrom = res.data.data;
      });
    },
    // 字典
    zidianlist: function () {
      // 类型
      GetCodeEntrysList({ kind: "254" }).then((res) => {
        this.leixing = res.data.data;
      });
      // 类型
      GetAbilityList({ kind: "202" }).then((res) => {
        this.hangye = res.data.data;
      });
      GetAbilityList().then((res) => {
        this.zhiye = res.data.data;
      });
      // 地区
      GetCodeEntrysList({ kind: "2" }).then((res) => {
        var Citys = [],
          Citysx = [],
          Citysq = [],
          Citysqp = [],
          list = res.data.data;
        for (var i = 0; i < list.length; i++) {
          if (list[i].Coding.length == "2") {
            Citys.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "4") {
            Citysx.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "6") {
            if (list[i].Coding == "410324") {
              Citysq.push({
                value: list[i].Coding,
                text: list[i].Alias,
                children: [],
              });
            } else {
              Citysq.push({ value: list[i].Coding, text: list[i].Alias });
            }
          } else if (list[i].Coding.length > "6") {
            if (list[i].Coding.substr(0, 6) == "410324") {
              Citysqp.push({ value: list[i].Coding, text: list[i].Alias });
            }
          }
        }
        for (var p = 0; p < Citysq.length; p++) {
          if (Citysq[p].value.substr(0, 6) == "410324") {
            Citysq[p].children = Citysqp;
          }
        }
        for (var i = 0; i < Citysq.length; i++) {
          for (var j = 0; j < Citysx.length; j++) {
            if (Citysq[i].value.substr(0, 4) == Citysx[j].value) {
              Citysx[j].children.push(Citysq[i]);
            }
          }
        }
        for (var z = 0; z < Citysx.length; z++) {
          for (var y = 0; y < Citys.length; y++) {
            if (Citysx[z].value.substr(0, 2) == Citys[y].value) {
              Citys[y].children.push(Citysx[z]);
            }
          }
        }
        this.NativeCantonCodedata = Citys;
        this.NativeCantonCodedataCs = Citys;
      });
    },
    onEType(val) {
      this.positionFrom.EType = val.Coding;
      this.positionFrom.ETypeDesc = val.Name;
      this.EType = false;
    },
    onFoundDate(val) {
      //个人详情 出生日期
      this.positionFrom.FoundDate = this.dateformat(val);
      this.FoundDate = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    onAbilityCode(val) {
      //状态况选择器
      this.positionFrom.IndCode = val.Coding;
      this.positionFrom.IndName = val.Name;
      this.AbilityCode = false;
    },
    onWorkAddrCode: function (value) {
      //工作地点
      var RegCity = this.addressfrom(value);
      this.positionFrom.WorkAddrCode = RegCity[0];
      this.positionFrom.WorkAddrName = RegCity[1];
      this.WorkAddrCode = false;
    },
    addressfrom(value) {
      //地址
      var a, b, c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + "," + b.text + "," + c.text;
      var address = [addressCode, addressName];
      return address;
    },
  },
};
</script>
<style>
</style>